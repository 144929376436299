import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

export default class Section extends Component {
  render() {
    const sectionClass = `flex flex-col-reverse md:justify-between ${this.props.reversed ? 'md:flex-row-reverse' : 'md:flex-row'}`
    return (
      <section className='md:py-20 section'>
        <div className='container'>
          <div className={sectionClass}>
            <ReactMarkdown source={this.props.section.content} className='w-full md:w-1/2 md:max-w-480 section-content' />
            <Img fluid={this.props.section.image.childImageSharp.fluid} className="w-full md:w-1/2" />
          </div>
        </div>
      </section>
    )
  }
}
