import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'

import Section from '@/components/Section'
import Layout from '@/components/Layout'
import SEO from '@/helpers/SEOHelper.js'

export const AboutPageTemplate = ({ data }) => {
  return (
    <>
      <section className='flex hero items-center justify-center pt-56 mb-10'>
        <div className='container text-center mx-auto relative'>
          <h1 className='title-1 font-bold max-w-2xl leading-none text-center mx-auto mb-6'>
            {ReactHtmlParser(data.hero.heading)}
          </h1>
        </div>
      </section>
      {data.sections.map((item, i) => (
        <Section key={i} section={item} reversed={i % 2 === 1} />
      ))}
    </>
  )
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <meta
          name='description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:title' content={SEO.title(frontmatter.seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:image' content={SEO.image('null')} />
        <title>{SEO.title(frontmatter.seo.title)}</title>
      </Helmet>
      <AboutPageTemplate data={frontmatter} />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      frontmatter {
        title
        hero {
          heading
          description
        }
        sections {
          content
          image {
            childImageSharp {
              fluid(maxWidth: 810, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        seo {
          title
          description
        }
      }
    }
  }
`
